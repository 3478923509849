export const state = () => ({
  data: null,
  choices: [],
});

export const mutations = {
  SET_DATA(state, data) {
    state.data = data;
  },
  CLEAN(state) {
    state.data = null;
    state.choices = [];
  },
  SET_CHOICES(state, choices) {
    state.choices = choices;
  },
  ADD_CHOICE(state, choice) {
    state.choices.push(choice);
  },
};

export const getters = {
  getQuery(state) {
    if (state.data) {
      return { law: state.data.systematicId };
    }
    return null;
  },
};

export const actions = {
  setData() {},
  toggleItem({ commit, state }, item) {
    const l = this.$i18n.locale;
    const m = l.charAt(0).toUpperCase() + l.slice(1);
    const key = `label${m}`;
    if (state.data.systematicId === item.systematicId) {
      commit('SET_DATA', null);
    } else {
      commit('SET_DATA', { ...item, displayName: item[key] });
    }
  },
  setChoices({ commit }, choices) {
    commit('SET_CHOICES', choices);
  },
  async fetchLaw({ commit }, lawId) {
    try {
      const { data } = await this.$HTTP.law(lawId);
      commit('ADD_CHOICE', data);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async fetchChoices({ commit }, query) {
    try {
      const { data } = await this.$HTTP.lawSearch(query);
      commit('SET_CHOICES', data.results);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  clean({ commit }) {
    commit('CLEAN');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
