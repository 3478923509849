import snakecaseKeys from 'snakecase-keys';
import { stringify } from 'qs';
import camelcaseKeys from 'camelcase-keys';
import BaseService from '~/services/BaseService';
import api from '~/configs/endpoints';

function paramsSerializer(params) {
  const snakeCaseParams = snakecaseKeys(params);
  return stringify(snakeCaseParams, { arrayFormat: 'repeat' });
}

export default class HTTP extends BaseService {
  constructor(ctx) {
    super(ctx);
    this.setLanguage(ctx.i18n.locale);
  }

  async get(uri, params = {}, isAFile = false) {
    try {
      const requestParams = { params, paramsSerializer };
      if (isAFile) {
        requestParams.responseType = 'blob';
      }
      const response = await this.$http.get(uri, requestParams);

      if (!isAFile) {
        response.data = camelcaseKeys(response.data, { deep: true });
      }
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  search(params = {}) {
    return this.get(api.search, params);
  }

  quickSearch(params = {}) {
    return this.get(api.quickSearch, params);
  }

  jurivocSearch(params = {}) {
    return this.get(api.jurivocSearch, params);
  }

  caseNumberSearch(params = {}) {
    return this.get(api.caseNumberSearch, params);
  }

  filePathSearch(params = {}) {
    return this.get(api.filePathSearch, params);
  }

  lawSearch(params = {}) {
    return this.get(api.lawSearch, params);
  }

  law(id, params) {
    return this.get(api.law(id), params);
  }

  articlesSearch(lawId) {
    const encodedLawId = lawId.replace('#', ':');
    return this.get(api.articlesSearch(encodedLawId));
  }

  documentDetail(id) {
    return this.get(api.documentDetail(id));
  }

  pages(id, params) {
    return this.get(api.pages(id), params);
  }

  documentDownload(id) {
    return this.get(api.documentDownload(id), {}, true);
  }

  export(params = {}) {
    return this.get(api.export, params, {}, true);
  }

  setLanguage(langCode) {
    this.$http.setHeader('Accept-Language', langCode);
  }
}
