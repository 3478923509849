<template>
  <a :href="link" target="_blank">
    <slot />
  </a>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Reference',
  props: {
    dataId: { type: String, required: true },
    dataKind: { type: String, required: true },
  },
  computed: {
    ...mapState('documents/metadata', {
      jurivoc: 'jurivoc',
      lexAtf: 'lexAtf',
      lexAtfOther: 'lexAtfOther',
      lexCh: 'lexCh',
      lexRvj: 'lexRvj',
      lexVs: 'lexVs',
    }),
    lawId() {
      return this.dataId.split('#')[0];
    },
    articleId() {
      return this.dataId.split('#')[1];
    },
    link() {
      if (this.dataKind === 'lex-ch') {
        return this.getArticleUrl(this.lexCh);
      } else if (this.dataKind === 'lex-vs') {
        return this.getArticleUrl(this.lexVs);
      } else if (this.dataKind === 'lex-atf') {
        return this.getAtfUrl(this.lexAtf);
      } else if (this.dataKind === 'lex-atf-other') {
        return this.getAtfUrl(this.lexAtfOther);
      } else if (this.dataKind === 'lex-rvj') {
        return '';
      }
      return '';
    },
  },
  methods: {
    getArticleUrl(metadata) {
      const laws = metadata.filter(law => law.id === this.lawId);
      if (laws.length) {
        const law = laws[0];
        if (law.articles) {
          const articles = Object.values(law.articles).filter(
            art => art.artId === this.articleId
          );
          if (articles.length) {
            return articles[0].url;
          }
        }
        return law.law.url;
      }
      return '';
    },
    getAtfUrl(metadata) {
      const atfs = metadata.filter(atf => atf.id === parseInt(this.dataId));
      if (atfs.length) {
        return atfs[0].url;
      }
      return '';
    },
  },
};
</script>
