export const state = () => ({
  data: [],
  choices: undefined,
});

export const mutations = {
  SET_DATA(state, data) {
    state.data = data;
  },
  CLEAN(state) {
    state.data = [];
    state.choices = [];
  },
  SET_CHOICES(state, choices) {
    state.choices = choices;
  },
};

export const getters = {
  getQuery(state) {
    if (state.data.length) {
      return { jurivocs: state.data.map(item => item.id) };
    }
    return {};
  },
};

export const actions = {
  setData({ commit, state }, data) {
    const newIds = data.filter(
      id => !state.data.map(item => item.id).includes(id)
    );

    const selectedItems = state.data.filter(item => data.includes(item.id));
    for (const newId of newIds) {
      selectedItems.push(state.choices.filter(c => c.id === newId)[0]);
    }
    commit('SET_DATA', selectedItems);
  },
  toggleItem({ commit, state }, item) {
    let items = [...state.data];
    if (items.map(i => i.id).includes(item.id)) {
      items = items.filter(i => i.id !== item.id);
    } else {
      items.push({ id: item.id, displayName: item.text });
    }
    commit('SET_DATA', items);
  },
  setDataFromQuery({ commit, dispatch, state }, { jurivocs }) {
    if (jurivocs) {
      let parsedData = jurivocs;
      if (!Array.isArray(jurivocs)) {
        parsedData = [jurivocs];
      }
      dispatch('fetchChoices', { id: parsedData }).then(() => {
        commit('SET_DATA', state.choices);
      });
    } else {
      commit('CLEAN');
    }
  },
  setChoices({ commit }) {},
  async fetchChoices({ commit, state }, query) {
    try {
      const { data } = await this.$HTTP.jurivocSearch(query);

      // Set displayName on value coming from query
      const values = [...state.data];
      if (values.filter(v => !v.displayName).length) {
        for (const value of values.filter(v => !v.displayName)) {
          const choices = data.results.filter(r => r.id === value.id);
          if (choices.length) {
            value.displayName = choices[0].displayName;
          }
        }
        commit('SET_DATA', values);
      }

      commit('SET_CHOICES', data.results);
    } catch (e) {
      return Promise.reject(e);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
