<template>
  <v-alert :type="alertType" class="alert" @click="$emit('dismissAlert')">
    {{ alertContent }}
  </v-alert>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    alertType: {
      type: String,
      required: false,
      default: 'info',
    },
    alertContent: {
      type: String,
      required: true,
    },
  },
};
</script>
<style scoped lang="scss">
.v-alert {
  max-width: 500px;
  margin: auto;
  margin-top: 0.25rem;
  cursor: pointer;
  pointer-events: all;
  margin-top: 0.2rem;
}
</style>
