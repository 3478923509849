export default {
  search: 'search/',
  quickSearch: 'search/quick/',
  jurivocSearch: 'search/metadata/jurivoc/',
  caseNumberSearch: 'search/metadata/case-number/',
  filePathSearch: 'search/metadata/file-path/',
  lawSearch: 'search/metadata/laws/',
  law: id => `search/metadata/laws/${id}/`,
  articlesSearch: id => `search/metadata/laws/${id}/articles`,
  documentDetail: id => `documents/${id}/`,
  pages: id => `documents/${id}/pages/`,
  documentDownload: id => `documents/${id}/file/`,
  export: 'search/export/',
};
