<template>
  <div class="language-selection">
    <v-btn-toggle v-model="$i18n.locale" group mandatory>
      <v-btn
        v-for="(lang, i) in $i18n.locales"
        :key="`lang_${i}`"
        :value="lang.code"
        @click.stop="setLang(lang.code)"
      >
        {{ lang.code }}
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
export default {
  name: 'LanguageSelection',
  methods: {
    setLang(langCode) {
      this.$i18n.setLocaleCookie(langCode);
      this.$HTTP.setLanguage(langCode);
    },
  },
};
</script>

<style lang="scss" scoped>
.language-selection {
  .v-btn-toggle {
    .v-btn {
      margin: 0;
      text-transform: uppercase;
      font-weight: 600;
      &--active {
        color: $text-dark-primary;
      }
      color: $text-dark-secondary;
      &:before {
        background: none;
      }
    }
  }
}
</style>
