export const state = () => ({
  data: {
    dateDecision: undefined,
    dateDecisionRange: [],
  },
  choices: undefined,
});

export const mutations = {
  SET_DATE_DECISION(state, dateDecision) {
    state.data.dateDecision = dateDecision;
    state.data.dateDecisionRange = [];
  },
  SET_DATE_DECISION_RANGE(state, dateDecisionRange) {
    state.data.dateDecisionRange = dateDecisionRange;
    state.data.dateDecision = undefined;
  },
  CLEAN(state) {
    state.data.dateDecision = undefined;
    state.data.dateDecisionRange = [];
    state.choices = [];
  },
  SET_CHOICES(state, choices) {
    state.choices = choices;
  },
};

export const getters = {
  getQuery(state) {
    const data = {};
    if (state.data.dateDecision) {
      data.dateDecision = state.data.dateDecision;
    } else if (state.data.dateDecisionRange.length === 2) {
      data.dateDecisionGte = state.data.dateDecisionRange[0];
      data.dateDecisionLte = state.data.dateDecisionRange[1];
    } else if (state.data.dateDecisionRange.length === 1) {
      data.dateDecisionGte = state.data.dateDecisionRange[0];
      data.dateDecisionLte = state.data.dateDecisionRange[0];
    }
    return data;
  },
};

export const actions = {
  setData({ commit }, { dateDecision, dateDecisionRange }) {
    commit('SET_DATE_DECISION', dateDecision);

    if (dateDecisionRange) {
      commit('SET_DATE_DECISION_RANGE', dateDecisionRange);
    }
  },
  toggleItem({ commit, state }, date) {
    const formatedDate = this.$moment(date).format('YYYY-MM-DD');
    let items = state.data.dateDecisionRange;
    if (items && items[0] === formatedDate) {
      items = [];
    } else {
      items = [formatedDate];
    }
    commit('SET_DATE_DECISION_RANGE', items);
  },
  setDataFromQuery(
    { commit, state },
    { dateDecision, dateDecisionGte, dateDecisionLte }
  ) {
    if (dateDecision) {
      commit('SET_DATE_DECISION', dateDecision);
    } else {
      commit('SET_DATE_DECISION', undefined);
    }

    const parsedDateDecisionRange = Array.from(state.data.dateDecisionRange);
    if (dateDecisionGte) {
      parsedDateDecisionRange.unshift(dateDecisionGte);
    }

    if (dateDecisionLte) {
      parsedDateDecisionRange.push(dateDecisionLte);
    }

    if (parsedDateDecisionRange.length) {
      commit('SET_DATE_DECISION_RANGE', parsedDateDecisionRange);
    }
  },

  setChoices({ commit }, { dateDecision }) {
    commit('SET_CHOICES', dateDecision);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
