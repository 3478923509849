<template>
  <v-app id="app" dark>
    <Alerts :alerts="alerts" @dismissAlert="dismissAlert" />
    <AppBar />
    <v-main id="main-container" class="main-container">
      <nuxt />
      <Footer />
    </v-main>
  </v-app>
</template>

<script>
import AppBar from '~/components/layouts/AppBar';
import Alerts from '~/components/containers/Alerts/Alerts.vue';
import Footer from '~/components/layouts/Footer.vue';
export default {
  name: 'LayoutDefault',
  components: {
    AppBar,
    Alerts,
    Footer,
  },
  data() {
    return {
      alerts: [],
    };
  },
  head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      titleTemplate: `%s - ${this.$t('title')}`,
    };
  },
  created() {
    this.$nuxt.$on('alert', alert => {
      this.alerts.push(alert);
      setTimeout(() => {
        const index = this.alerts.indexOf(alert);
        this.alerts.splice(index, 1);
      }, 6000);
    });
  },
  methods: {
    dismissAlert(index) {
      this.alerts.splice(index, 1);
    },
  },
};
</script>

<style lang="scss">
body {
  margin: 0;
}
.v-main {
  flex: 1 0 0 !important;
  overflow: auto;
}
.v-main__wrap {
  align-items: flex-start !important;
}
.main-container {
  .v-main__wrap {
    display: flex;
    flex-direction: column !important;
    & > .container {
      margin-top: 5rem;
      margin-bottom: 7rem;
      flex: 1;
    }
  }
}
</style>
