export default (ctx, inject) => {
  inject('notifyErrorResponse', error => {
    const isJSONContent =
      error.response &&
      error.response.headers['content-type'] === 'application/json';
    const content = isJSONContent
      ? error.response.detail
      : ctx.i18n.t('alert.error');
    ctx.$notifyError(content);
  });
  inject('notifyError', errorMessage => {
    const alert = { type: 'error', content: errorMessage };
    window.$nuxt.$emit('alert', alert);
  });
};
