export const state = () => ({
  data: [],
  choices: undefined,
});

export const mutations = {
  SET_DATA(state, data) {
    state.data = data;
  },
  CLEAN(state) {
    state.data = [];
    state.choices = [];
  },
  SET_CHOICES(state, choices) {
    state.choices = choices;
  },
};

export const getters = {
  getQuery(state) {
    if (state.data.length) {
      return { rapporteurs: state.data };
    }
    return {};
  },
};

export const actions = {
  setData({ commit }, data) {
    commit('SET_DATA', data);
  },
  setDataFromQuery({ commit }, { rapporteurs }) {
    if (rapporteurs) {
      let parsedData = rapporteurs;

      if (!Array.isArray(rapporteurs)) {
        parsedData = [rapporteurs];
      }

      commit('SET_DATA', parsedData);
    } else {
      commit('SET_DATA', []);
    }
  },
  setChoices({ commit }, { rapporteurs }) {
    commit('SET_CHOICES', rapporteurs);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
