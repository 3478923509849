export const state = () => ({
  data: false,
  choices: undefined,
});

export const mutations = {
  SET_DATA(state, data) {
    state.data = data;
  },
  CLEAN(state) {
    state.data = false;
  },
  SET_CHOICES(state, choices) {
    state.choices = choices;
  },
  CLEAR_CHOICES(state) {
    state.choices = [];
  },
};

export const getters = {
  getQuery(state) {
    if (state.data) {
      return { exact: state.data };
    }
    return {};
  },
};

export const actions = {
  setData({ commit }, data) {
    commit('SET_DATA', data);
  },
  setDataFromQuery({ commit }, { exact }) {
    commit('SET_DATA', Boolean(exact));
  },
  setChoices({ commit }) {},
  clearChoices({ commit }) {
    commit('CLEAR_CHOICES');
  },
  async fetchChoices({ commit }, query) {
    if (query.exact) {
      try {
        const { data } = await this.$HTTP.quickSearch(query);
        commit('SET_CHOICES', data.results);
      } catch (e) {
        return Promise.reject(e);
      }
    } else {
      commit('CLEAR_CHOICES');
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
