export const state = () => ({
  jurivoc: [],
  lexAtf: [],
  lexAtfOther: [],
  lexCh: [],
  lexRvj: [],
  lexVs: [],
});

export const mutations = {
  SET_JURIVOC(state, jurivoc) {
    state.jurivoc = jurivoc;
  },
  SET_LEXATF(state, lexAtf) {
    state.lexAtf = lexAtf;
  },
  SET_LEXATFOTHER(state, lexAtfOther) {
    state.lexAtfOther = lexAtfOther;
  },
  SET_LEXCH(state, lexCh) {
    state.lexCh = lexCh;
  },
  SET_LEXRVJ(state, lexRvj) {
    state.lexRvj = lexRvj;
  },
  SET_LEXVS(state, lexVs) {
    state.lexVs = lexVs;
  },
};

export const actions = {
  setMetadataFromDocument({ commit }, document) {
    commit('SET_JURIVOC', document.metadataJurivoc);
    commit('SET_LEXATF', document.metadataLexAtf);
    commit('SET_LEXATFOTHER', document.metadataLexAtfOther);
    commit('SET_LEXCH', document.metadataLexCh);
    commit('SET_LEXRVJ', document.metadataLexRvj);
    commit('SET_LEXVS', document.metadataLexVs);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
