export const state = () => ({
  data: {
    dateFileModification: undefined,
    dateFileModificationRange: [],
  },
  choices: undefined,
});

export const mutations = {
  SET_DATE_FILE_MODIFICATION(state, dateFileModification) {
    state.data.dateFileModification = dateFileModification;
    state.data.dateFileModificationRange = [];
  },
  SET_DATE_FILE_MODIFICATION_RANGE(state, dateFileModificationRange) {
    state.data.dateFileModificationRange = dateFileModificationRange;
    state.data.dateFileModification = undefined;
  },
  CLEAN(state) {
    state.data.dateFileModification = undefined;
    state.data.dateFileModificationRange = [];
    state.choices = [];
  },
  SET_CHOICES(state, choices) {
    state.choices = choices;
  },
};

export const getters = {
  getQuery(state) {
    const data = {};
    if (state.data.dateFileModification) {
      data.dateFileModification = state.data.dateFileModification;
    } else if (state.data.dateFileModificationRange.length === 2) {
      data.dateFileModificationGte = state.data.dateFileModificationRange[0];
      data.dateFileModificationLte = state.data.dateFileModificationRange[1];
    } else if (state.data.dateFileModificationRange.length === 1) {
      data.dateFileModificationGte = state.data.dateFileModificationRange[0];
      data.dateFileModificationLte = state.data.dateFileModificationRange[0];
    }
    return data;
  },
};

export const actions = {
  setData({ commit }, { dateFileModification, dateFileModificationRange }) {
    commit('SET_DATE_FILE_MODIFICATION', dateFileModification);

    if (dateFileModificationRange) {
      commit('SET_DATE_FILE_MODIFICATION_RANGE', dateFileModificationRange);
    }
  },
  toggleItem({ commit, state }, date) {
    const formatedDate = this.$moment(date).format('YYYY-MM-DD');
    let items = state.data.dateFileModificationRange;
    if (items && items[0] === formatedDate) {
      items = [];
    } else {
      items = [formatedDate];
    }
    commit('SET_DATE_FILE_MODIFICATION_RANGE', items);
  },
  setDataFromQuery(
    { commit, state },
    { dateFileModification, dateFileModificationGte, dateFileModificationLte }
  ) {
    if (dateFileModification) {
      commit('SET_DATE_FILE_MODIFICATION', dateFileModification);
    } else {
      commit('SET_DATE_FILE_MODIFICATION', undefined);
    }

    const parsedDateFileModificationRange = Array.from(
      state.data.dateFileModificationRange
    );
    if (dateFileModificationGte) {
      parsedDateFileModificationRange.unshift(dateFileModificationGte);
    }

    if (dateFileModificationLte) {
      parsedDateFileModificationRange.push(dateFileModificationLte);
    }

    if (parsedDateFileModificationRange.length) {
      commit(
        'SET_DATE_FILE_MODIFICATION_RANGE',
        parsedDateFileModificationRange
      );
    }
  },

  setChoices({ commit }, { dateFileModification }) {
    commit('SET_CHOICES', dateFileModification);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
