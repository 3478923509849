const STORES_PARAMS_LIST = [
  { storeName: 'query' },
  { storeName: 'exact' },
  {
    storeName: 'tribunals',
    choicesAreAsync: false,
    componentName: 'TribunalsContainer',
  },
  {
    storeName: 'dateDecision',
    choicesAreAsync: false,
    componentName: 'DateDecisionContainer',
  },
  {
    storeName: 'datePublication',
    choicesAreAsync: false,
    componentName: 'DatePublicationContainer',
  },
  {
    storeName: 'dateFileModification',
    choicesAreAsync: false,
    componentName: 'DateFileModificationContainer',
  },
  {
    storeName: 'articles',
    choicesAreAsync: true,
    componentName: 'lawsAndArticlesContainer',
  },
  {
    storeName: 'documentTypes',
    choicesAreAsync: false,
    componentName: 'DocumentTypesContainer',
  },
  {
    storeName: 'objectTypes',
    choicesAreAsync: false,
    componentName: 'ObjectTypesContainer',
  },
  {
    storeName: 'caseInstances',
    choicesAreAsync: false,
    componentName: 'CaseInstancesContainer',
  },

  {
    storeName: 'legalNatures',
    choicesAreAsync: false,
    componentName: 'LegalNaturesContainer',
  },
  {
    storeName: 'presidents',
    choicesAreAsync: false,
    componentName: 'PresidentsContainer',
  },
  {
    storeName: 'clerks',
    choicesAreAsync: false,
    componentName: 'ClerksContainer',
  },
  {
    storeName: 'rapporteurs',
    choicesAreAsync: false,
    componentName: 'RapporteursContainer',
  },
  {
    storeName: 'documentLanguages',
    choicesAreAsync: false,
    componentName: 'LanguageContainer',
  },
  {
    storeName: 'caseNumbers',
    choicesAreAsync: true,
    componentName: 'CaseNumbersContainer',
  },
  {
    storeName: 'filePath',
    choicesAreAsync: true,
    onlyPrivate: true,
    componentName: 'FilePathContainer',
  },
  {
    storeName: 'jurivocs',
    choicesAreAsync: true,
    componentName: 'JurivocsContainer',
  },
];

export { STORES_PARAMS_LIST };
