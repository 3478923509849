<template>
  <v-container>
    <div class="d-flex">
      <v-btn color="primary" to="/" text>
        <v-icon left dark v-text="'mdi-arrow-left'" />
        {{ local.linkLabel }}
      </v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  layout: 'error',
  props: {
    error: { type: Object },
  },
  head() {
    return {
      title: this.message,
    };
  },
  computed: {
    local() {
      return this.$t('pages').error;
    },
    message() {
      const errors = this.local.errors;
      return this.error.statusCode === 404
        ? errors.pageNotFound
        : errors.otherError;
    },
  },
};
</script>
