import Vue from 'vue';
import DocumentLegislation from '~/components/injectables/DocumentLegislation';
import DocumentAnonymization from '~/components/injectables/DocumentAnonymization';
import Reference from '~/components/injectables/Reference';

// eslint-disable-next-line vue/component-definition-name-casing
Vue.component('justsearch-anonymisation', DocumentAnonymization);
// eslint-disable-next-line vue/component-definition-name-casing
Vue.component('justsearch-doc-legislation', DocumentLegislation);
// eslint-disable-next-line vue/component-definition-name-casing
Vue.component('justsearch-reference', Reference);
