<template>
  <transition-group name="list" class="alerts" @before-leave="beforeLeave">
    <Alert
      v-for="(alert, i) in alerts"
      :key="`alert-${i}`"
      :alert-type="alert.type"
      :alert-content="alert.content"
      @dismissAlert="dismissAlert(i)"
    />
  </transition-group>
</template>

<script>
import Alert from '~/components/containers/Alerts/Alert';

export default {
  name: 'Alerts',
  components: {
    Alert,
  },
  props: {
    alerts: {
      type: Array,
      required: true,
    },
  },
  methods: {
    beforeLeave(el) {
      const rect = el.getBoundingClientRect();
      el.style.width = `${rect.width}px`;
      el.style.top = `${el.offsetTop}px`;
      el.style.left = `${el.offsetLeft}px`;
    },
    dismissAlert(index) {
      this.$emit('dismissAlert', index);
    },
  },
};
</script>

<style scoped lang="scss">
.alerts {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  pointer-events: none;
}
.list-enter-active,
.list-move,
.list-leave-active {
  transition: all 500ms cubic-bezier(0.59, 0.12, 0.34, 0.95);
}
.list-enter {
  opacity: 0;
  transform: translateY(-75%);
  border-color: #c2c2c2;
}

.list-enter-to {
  opacity: 1;
  transform: translateY(0);
}

.list-leave-active {
  position: absolute;
}

.list-leave-to {
  opacity: 0;
  transform: translateY(-100%);
}
</style>
