export const state = () => ({
  data: {
    datePublication: undefined,
    datePublicationRange: [],
  },
  choices: undefined,
});

export const mutations = {
  SET_DATE_PUBLICATION(state, datePublication) {
    state.data.datePublication = datePublication;
    state.data.datePublicationRange = [];
  },
  SET_DATE_PUBLICATION_RANGE(state, datePublicationRange) {
    state.data.datePublicationRange = datePublicationRange;
    state.data.datePublication = undefined;
  },
  CLEAN(state) {
    state.data.datePublication = undefined;
    state.data.datePublicationRange = [];
    state.choices = [];
  },
  SET_CHOICES(state, choices) {
    state.choices = choices;
  },
};

export const getters = {
  getQuery(state) {
    const data = {};
    if (state.data.datePublication) {
      data.datePublication = state.data.datePublication;
    } else if (state.data.datePublicationRange.length === 2) {
      data.datePublicationGte = state.data.datePublicationRange[0];
      data.datePublicationLte = state.data.datePublicationRange[1];
    } else if (state.data.datePublicationRange.length === 1) {
      data.datePublicationGte = state.data.datePublicationRange[0];
      data.datePublicationLte = state.data.datePublicationRange[0];
    }
    return data;
  },
};

export const actions = {
  setData({ commit }, { datePublication, datePublicationRange }) {
    commit('SET_DATE_PUBLICATION', datePublication);

    if (datePublicationRange) {
      commit('SET_DATE_PUBLICATION_RANGE', datePublicationRange);
    }
  },
  toggleItem({ commit, state }, date) {
    const formatedDate = this.$moment(date).format('YYYY-MM-DD');
    let items = state.data.datePublicationRange;
    if (items && items[0] === formatedDate) {
      items = [];
    } else {
      items = [formatedDate];
    }
    commit('SET_DATE_PUBLICATION_RANGE', items);
  },
  setDataFromQuery(
    { commit, state },
    { datePublication, datePublicationGte, datePublicationLte }
  ) {
    if (datePublication) {
      commit('SET_DATE_PUBLICATION', datePublication);
    } else {
      commit('SET_DATE_PUBLICATION', undefined);
    }

    const parsedDatePublicationRange = Array.from(
      state.data.datePublicationRange
    );
    if (datePublicationGte) {
      parsedDatePublicationRange.unshift(datePublicationGte);
    }

    if (datePublicationLte) {
      parsedDatePublicationRange.push(datePublicationLte);
    }

    if (parsedDatePublicationRange.length) {
      commit('SET_DATE_PUBLICATION_RANGE', parsedDatePublicationRange);
    }
  },

  setChoices({ commit }, { datePublication }) {
    commit('SET_CHOICES', datePublication);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
