import { STORES_PARAMS_LIST } from '~/configs/constants';
export const state = () => ({
  data: [],
  count: 0,
  limit: parseInt(process.env.NUXT_ENV_APP_API_LIMIT),
  offset: 0,
  sort: '_score',
  lastQuery: null,
});

export const mutations = {
  CLEAN(state) {
    state.data = [];
    state.count = 0;
    state.limit = parseInt(process.env.NUXT_ENV_APP_API_LIMIT);
    state.offset = 0;
  },
  SET_DATA(state, data) {
    state.data = data;
  },
  SET_COUNT(state, count) {
    state.count = count;
  },
  SET_LIMIT(state, limit) {
    state.limit = limit;
  },
  SET_OFFSET(state, offset) {
    state.offset = offset;
  },
  SET_SORT(state, sort) {
    state.sort = sort;
  },
  SET_LAST_QUERY(state, query) {
    state.lastQuery = query;
  },
};

export const actions = {
  async getSearchFilters({ rootState }, { query, data }) {
    const filters = {};
    for (const key in query) {
      const singleValues = ['q'];
      if (singleValues.includes(key)) {
        filters[key] = [query[key]];
      }
      const dates = ['dateDecision', 'datePublication'];
      if (dates.includes(key)) {
        filters[key] = [this.app.i18n.t(`datePicker.${query[key]}`)];
      }
      const dateRange = [
        'dateDecisionGte',
        'dateDecisionLte',
        'datePublicationGte',
        'datePublicationLte',
      ];
      if (dateRange.includes(key)) {
        const _key = key.slice(0, key.length - 3);
        const format = 'DD/MM/YYYY';
        const date = this.$moment(query[key]).format(format);
        const array = filters[_key] || [];
        filters[_key] = [...array, date];
      }
      const filtersName = [
        'tribunals',
        'caseInstances',
        'documentTypes',
        'documentLanguages',
        'legalNatures',
      ];
      if (filtersName.includes(key)) {
        const values = rootState.search.params[key].data.map(d => {
          const facets = data
            ? data.facets[key]
            : rootState.search.params[key].choices;
          return facets.filter(c => {
            const choiceId = !isNaN(parseInt(c.id)) ? parseInt(c.id) : c.id;
            const dataId = !isNaN(parseInt(c.id)) ? parseInt(d) : d;
            return choiceId === dataId;
          })[0].displayName;
        });
        filters[key] = values;
      }

      const asyncFilters = ['jurivocs'];
      if (asyncFilters.includes(key)) {
        while (rootState.search.params[key].data.length === 0) {
          await new Promise(resolve => setTimeout(resolve, 200));
        }
        const values = rootState.search.params[key].data.map(
          d => d.displayName
        );
        filters[key] = values;
      }
    }
    return filters;
  },
  async fetchResults(
    { commit, rootGetters, dispatch, state },
    { loadResult = true }
  ) {
    const query = rootGetters['search/getQuery'];
    if (JSON.stringify(query) !== JSON.stringify(state.lastQuery)) {
      commit('SET_DATA', []);
      commit('SET_COUNT', 0);
      try {
        const { data } = await this.$HTTP.search(query);
        if (loadResult) {
          commit('SET_DATA', data.results);
          commit('SET_COUNT', data.count);
          commit('SET_OFFSET', data.offset);
        }
        dispatch('getSearchFilters', { query, data }).then(filters => {
          if (Object.keys(filters).length) {
            const recentSearches = JSON.parse(
              localStorage.getItem('recentSearches') || '{}'
            );
            const timeStamp = Date.now();
            recentSearches[timeStamp] = {
              query,
              timeStamp,
              filters,
            };
            const recentSearchesKeys = Object.keys(recentSearches);
            if (recentSearchesKeys.length > 6) {
              delete recentSearches[recentSearchesKeys[5]];
            }
            localStorage.setItem(
              'recentSearches',
              JSON.stringify(recentSearches)
            );
          }
        });

        for (const storeParams of STORES_PARAMS_LIST) {
          dispatch(
            `search/params/${storeParams.storeName}/setChoices`,
            data.facets,
            {
              root: true,
            }
          );
        }
        commit('SET_LAST_QUERY', query);
      } catch (e) {
        return Promise.reject(e);
      }
    }
  },
  setOffset({ commit }, { offset }) {
    if (offset !== undefined) {
      commit('SET_OFFSET', parseInt(offset));
    }
  },
  setLimit({ commit }, { limit }) {
    if (limit) {
      commit('SET_LIMIT', limit);
    }
  },
  setSort({ commit }, { sort = '_score' }) {
    commit('SET_SORT', sort);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
