export const state = () => ({
  data: [],
  choices: undefined,
});

export const mutations = {
  SET_DATA(state, data) {
    state.data = data;
  },
  CLEAN(state) {
    state.data = [];
    state.choices = [];
  },
  SET_CHOICES(state, choices) {
    state.choices = choices;
  },
};

export const getters = {
  getQuery(state) {
    if (state.data.length) {
      return { clerks: state.data };
    }
    return {};
  },
};

export const actions = {
  setData({ commit }, data) {
    commit('SET_DATA', data);
  },
  setDataFromQuery({ commit }, { clerks }) {
    if (clerks) {
      let parsedData = clerks;

      if (!Array.isArray(clerks)) {
        parsedData = [clerks];
      }

      commit('SET_DATA', parsedData);
    } else {
      commit('SET_DATA', []);
    }
  },
  setChoices({ commit }, { clerks }) {
    commit('SET_CHOICES', clerks);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
