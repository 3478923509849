<template>
  <div :class="['anon-wrapper', `color-${dataId.toLowerCase()}`]">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'DocumentAnonymization',
  props: {
    dataId: { type: String, required: true },
  },
};
</script>

<style lang="scss">
.anon-wrapper {
  display: inline-block;
  position: relative;
  z-index: 1;
  padding: 0rem 0.25rem;
  border-radius: 0.125rem;
}
$colors: #ffebee, #fce4ec, #f3e5f5, #ede7f6, #e8eaf6, #e3f2fd, #e1f5fe, #e0f7fa,
  #e0f2f1, #e8f5e9, #f1f8e9, #f9fbe7, #fffde7, #fff8e1, #fff3e0, #fbe9e7,
  #efebe9, #fafafa, #eceff1;
$text: #b71c1c, #880e4f, #4a148c, #311b92, #1a237e, #0d47a1, #01579b, #006064,
  #004d40, #1b5e20, #33691e, #524c00, #bc5100, #c43e00, #e65100, #bf360c,
  #3e2723, #212121, #263238;
$chars: 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n',
  'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z';
@each $char in $chars {
  $i: index($chars, $char);
  $bg-color: nth($colors, $i % length($colors) + 1);
  $txt-color: nth($text, $i % length($text) + 1);
  .color-#{$char} {
    background-color: fade-out($bg-color, 0.5);
    color: $txt-color;
    font-weight: bold;
  }
}
</style>
