import Vue from 'vue';
import VueMatomo from 'vue-matomo';

export default ({ app, route, $config }) => {
  if ($config.matomoUrl) {
    Vue.use(VueMatomo, {
      host: $config.matomoUrl,
      siteId: $config.matomoSiteId,
      trackerFileName: 'matomo',
      router: app.router,
      enableLinkTracking: true,
      requireConsent: false,
      trackInitialView: true,
      disableCookies: false,
      enableHeartBeatTimer: false,
      heartBeatTimerInterval: 15,
    });
  }
};
