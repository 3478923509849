<template>
  <v-footer class="footer">
    <v-container>
      <a :href="helpLink" target="_blan">
        {{ $t('documentations.quickHelp') }}</a
      >
      <a :href="userManual" target="_blank">
        {{ $t('documentations.documentation') }}</a
      >
      <a href="https://www.vs.ch/web/tribunaux/gestion" target="_blank">
        {{ $t('documentations.contact') }}
      </a>
      <a href="https://arcanite.ch/" class="ml-auto">
        {{ $t('footer.poweredByArcanite') }}
      </a>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    helpLink() {
      return `${process.env.NUXT_ENV_APP_DOCUMENTATION}help/?lang=${this.$i18n.locale}`;
    },
    userManual() {
      return `${process.env.NUXT_ENV_APP_DOCUMENTATION}user-manual/?lang=${this.$i18n.locale}`;
    },
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss">
.v-footer.footer {
  background: $background-dark-secondary !important;
  width: 100%;
  padding: 0rem 0rem;
  .container {
    display: flex;
    gap: 0 3rem;
  }
  a {
    text-decoration: none;
    font-weight: 500;
    color: fade-out($text-dark-primary, 0.2) !important;
    transition: $transition-bezier 200ms;
    font-size: 0.9rem;
    &:hover {
      color: $text-dark-primary !important;
    }
  }
}
</style>
