export const state = () => ({
  data: [],
  choices: [],
});

export const mutations = {
  SET_DATA(state, data) {
    state.data = data;
  },
  CLEAN(state) {
    state.data = [];
    state.choices = [];
  },
  SET_CHOICES(state, choices) {
    state.choices = choices;
  },
};

export const getters = {
  getQuery(state) {
    if (state.data.length !== 0) {
      return { articles: state.data.map(item => item.systematicId) };
    }
    return {};
  },
};

export const actions = {
  setData({ commit }, data) {
    commit('SET_DATA', data);
  },
  async setDataFromQuery({ commit, dispatch, state }, { articles }) {
    if (articles) {
      let parsedData = articles;

      if (!Array.isArray(articles)) {
        parsedData = [articles];
      }

      parsedData = parsedData.map(systematicId => {
        const parts = systematicId.split(':');
        const lawId = parts.slice(0, -1).join(':');
        return { lawId, systematicId };
      });

      const encounteredLawIds = new Set();
      const articlesSelectionID = new Set(state.data.map(a => a.systematicId));

      for (const { lawId, systematicId } of parsedData) {
        const { data } = await this.$HTTP.articlesSearch(lawId);
        const selectedArticle = data.results.find(
          a => a.systematicId === systematicId
        );
        if (selectedArticle) {
          const selectedArticleID = selectedArticle.systematicId;
          if (!articlesSelectionID.has(selectedArticleID)) {
            commit('SET_DATA', [...state.data, selectedArticle]);
            articlesSelectionID.add(selectedArticleID);
          }

          if (encounteredLawIds.has(lawId)) {
            continue;
          }

          dispatch('search/params/laws/fetchLaw', lawId, {
            root: true,
          });
          encounteredLawIds.add(lawId);
        }
      }
    } else {
      commit('SET_DATA', []);
    }
  },
  setChoices() {},
  async fetchChoices({ commit }, lawId) {
    try {
      const { data } = await this.$HTTP.articlesSearch(lawId);
      commit('SET_CHOICES', data.results);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  clean({ commit }) {
    commit('CLEAN');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
