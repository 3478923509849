export const state = () => ({
  data: [],
  choices: undefined,
});

export const mutations = {
  SET_DATA(state, data) {
    state.data = data;
  },
  CLEAN(state) {
    state.data = [];
    state.choices = [];
  },
  SET_CHOICES(state, choices) {
    state.choices = choices;
  },
};

export const getters = {
  getQuery(state) {
    if (state.data.length) {
      return { caseInstances: state.data };
    }
    return {};
  },
};

export const actions = {
  setData({ commit }, data) {
    commit('SET_DATA', data);
  },
  toggleItem({ commit, state }, id) {
    let items = [...state.data];
    if (items.includes(id)) {
      items = items.filter(i => i !== id);
    } else {
      items.push(id);
    }
    commit('SET_DATA', items);
  },
  setDataFromQuery({ commit }, { caseInstances }) {
    if (caseInstances) {
      let parsedData = caseInstances;

      if (!Array.isArray(caseInstances)) {
        parsedData = [caseInstances];
      }

      parsedData = parsedData.map(d => parseInt(d));

      commit('SET_DATA', parsedData);
    } else {
      commit('SET_DATA', []);
    }
  },
  setChoices({ commit }, { caseInstances }) {
    commit('SET_CHOICES', caseInstances);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
