import { STORES_PARAMS_LIST } from '~/configs/constants';

export const getters = {
  getQuery(state, getters) {
    let query = {};

    query.offset = state.results.offset;
    query.limit = state.results.limit;
    query.sort = state.results.sort;

    for (const storeParams of STORES_PARAMS_LIST) {
      const subQuery = getters[`params/${storeParams.storeName}/getQuery`];
      query = Object.assign(query, subQuery);
    }

    return query;
  },

  getActiveComponentsList(state, getters) {
    const activeComponents = [];
    for (const storeParams of STORES_PARAMS_LIST.filter(
      params => params.componentName
    )) {
      if (
        process.env.NUXT_ENV_APP_ENV !== 'prive' &&
        !!storeParams.onlyPrivate
      ) {
        continue;
      }

      if (
        storeParams.choicesAreAsync ||
        state.params[storeParams.storeName].choices !== undefined
      ) {
        activeComponents.push({
          name: storeParams.componentName,
          param: storeParams.storeName,
        });
      }
    }
    return activeComponents;
  },
};

export const actions = {
  clean({ commit }) {
    for (const storeParams of STORES_PARAMS_LIST) {
      commit(`params/${storeParams.storeName}/CLEAN`);
    }
  },

  loadFromQuery({ dispatch }, query) {
    for (const storeParams of STORES_PARAMS_LIST) {
      dispatch(`params/${storeParams.storeName}/setDataFromQuery`, query);
    }
    dispatch('results/setOffset', query);
    dispatch('results/setLimit', query);
    dispatch('results/setSort', query);
  },
};

export default {
  namespaced: true,
  getters,
  actions,
};
