<template>
  <v-app-bar
    flat
    app
    height="64"
    dark
    class="app-bar d-flex justify-center navbar"
    :class="{ 'app-bar--gradient': $route.name !== 'document' }"
  >
    <a class="brand-name white--text" href="/">
      <img :src="imgSrc" :alt="$t('header.logoAlt')" />
    </a>
    <LanguageSelection class="ml-12" />
    <v-btn v-if="$auth.loggedIn" @click="$auth.logout()">
      {{ $t('auth.logout') }} ({{ $auth.user.email }})
    </v-btn>
  </v-app-bar>
</template>

<script>
import LanguageSelection from '~/components/elements/LanguageSelection';

export default {
  name: 'AppBar',
  components: { LanguageSelection },
  computed: {
    appName() {
      return process.env.NUXT_ENV_APP_NAME;
    },
    helpLink() {
      return `${process.env.NUXT_ENV_APP_DOCUMENTATION}help/?lang=${this.$i18n.locale}`;
    },
    userManual() {
      return `${process.env.NUXT_ENV_APP_DOCUMENTATION}user-manual/?lang=${this.$i18n.locale}`;
    },
    locale() {
      return this.$i18n.locale;
    },
    imgSrc() {
      return require(`~/assets/img/logo_${this.locale}.svg`);
    },
  },
};
</script>

<style lang="scss">
.app-bar {
  &--gradient {
    &:before {
      pointer-events: none;
      content: '';
      display: block;
      width: 100%;
      height: 2rem;
      background: linear-gradient(
        $background-primary 0%,
        fade-out($background-primary, 1)
      );
      top: 100%;
      left: 0;
      position: absolute;
    }
  }

  img {
    height: 2.7rem;
  }
}
.v-toolbar__content {
  flex: 1;
  margin: 0 4rem;
  justify-content: space-between;
}
.brand-name {
  text-decoration: none;
}

.navbar {
  z-index: 10 !important;
}
</style>
