<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'DocumentLegislation',
  props: {
    dataLegislation: { type: String, required: true },
    dataLexId: { type: String, required: true },
  },
};
</script>
